(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/animation/assets/javascripts/animation.js') >= 0) return;  svs.modules.push('/components/components/animation/assets/javascripts/animation.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.Animation = function() {
    return {
      slide: {
        DURATION: 200,
        EASING: 'swing',
        down: function(element, complete) {
          $(element)
            .stop()
            .slideDown({
              duration: this.DURATION,
              easing: this.EASING,
              complete: complete
            });
        },
        up: function(element, complete) {
          $(element)
            .stop()
            .slideUp({
              duration: this.DURATION,
              easing: this.EASING,
              complete: complete
            });
        },
        toggle: function(element, complete) {
          $(element)
            .stop()
            .slideToggle({
              duration: this.DURATION,
              easing: this.EASING,
              complete: complete
            });
        }
      },
      bounce: {
        DURATION: 300
      },
      add: function(element, classNames, callback) {
        var $el;
        if (element instanceof jQuery) {
          $el = element;
        } else {
          $el = $(element);
        }
        $el.addClass(classNames);
        $el.on(
          'webkitAnimationEnd oanimationend msAnimationEnd animationend',
          function() {
            $(this).removeClass(classNames);
            if (callback) {
              callback();
            }

            $el.off('webkitAnimationEnd oanimationend msAnimationEnd animationend');
          }
        );
      }
    };
  };

  svs.components.animation = svs.components.Animation();
})(svs);


 })(window);